import "../styles/AccessInfo.css";
import {LogOut} from "../api/Api";
import {useRecoilState} from "recoil";
import {IsLogin, userInfo} from "./UserInfo";

export default function AccessInfo({user}) {
    const [isLogin, setIsLogin] = useRecoilState(IsLogin);
    const [userData, setUserData] = useRecoilState(userInfo);

    const handleLogout = async () => {
        const log_out = await LogOut();
        setIsLogin(false);
        setUserData({});
    }

    if (user.authority_type === 'agency') {
        return (
            <div className="accessInfo">
                <div className="inventoryStatus">
                    <div>재고현황</div>
                    <div>Authentic-L</div>
                    <div>{Math.round(user.stock * 100) / 100} (m)</div>
                </div>
                <div className="accessController">
                    <p>{user.name}님</p>
                    <button id="logoutBtn" className="grayBtn" onClick={handleLogout}>로그아웃</button>
                </div>
            </div>
        )

    } else {
        return (
            <div className="accessController">
                <p>{user.name}님</p>
                <button id="logoutBtn" className="grayBtn" onClick={handleLogout}>로그아웃</button>
            </div>
        )
    }
}