import axios from "axios";
import {useRecoilState} from "recoil";
import {IsLogin} from "../components/UserInfo";
import {useNavigate} from "react-router-dom";


const BASE_URL = "https://lexus-smithclub.net:9027";
const AUTH_BASE_URL = `${BASE_URL}/api/auth`;
const UTIL_BASE_URL = `${BASE_URL}/api/util`;
const ADMIN_BASE_URL = `${BASE_URL}/api/admin`;
const SRC_BASE_URL = `${BASE_URL}/api/src`;


function getCookie(cookieName) {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';').map(cookie => cookie.trim().split('='));
    const cookie = cookies.find(([name]) => name === cookieName);
    return cookie ? cookie[1] : null;
}

// 아이디 중복 체크
export const idDupCheck = async ({id}) => {
    try {
        const response = await axios.get(`${AUTH_BASE_URL}/id_dup_check`, {
            params: {user_id: id},
        })
        return response.status;
    } catch (error) {
        console.log("ID DUP CHECK ERROR : ", error);
    }
}

// 인증 이메일 전송
export const smsSend = async (phone_number) => {
    try {
        const response = await axios.get(`${AUTH_BASE_URL}/phone_number_auth`, {
            params: {phone_number: phone_number},
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 417) {
            alert("메세지 발송에 실패했습니다. 잠시 후 다시 시도해주세요.");
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.log("AUTH SMS SEND ERROR : ", error);
    }
}

// 이메일 인증 확인
export const smsCheck = async (phoneNumber, authNumber) => {
    try {
        const response = await axios.get(`${AUTH_BASE_URL}/phone_number_auth_check`, {
            params: {
                phone_number: phoneNumber,
                auth_number: authNumber,
            },
        })
        return response.status;
    } catch (error) {
        console.log("AUTH SMS CHECK ERROR : ", error);
    }
}

// 회원가입
export const signUp = async ({bodyData}) => {
    try {
        const response = await axios.post(`${AUTH_BASE_URL}/signup`, bodyData);
        return response.status;
    } catch (error) {
        if (error.response?.status === 414) {
            alert("이미 존재하는 아이디입니다.");
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.log("SIGNUP ERROR : ", error);
    }
};

// 로그인
export const LogIn = async ({bodyData}) => {
    try {
        const response = await axios.post(`${AUTH_BASE_URL}/login`, bodyData, {
            headers: {
                "Content-Type": "application/json",
            }
        });
        if (response.status === 200) {
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            document.cookie = `session_id=${response.data.session_id}; path=/`;
            return response.status;
        }
    } catch (error) {
        if (error.response?.status === 415) {
            alert("아이디 또는 비밀번호가 일치하지 않습니다.");
        } else if (error.response?.status === 416) {
            alert("삭제된 사용자입니다.");
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.log("LOGIN ERROR : ", error);
    }
};

// 로그아웃
export const LogOut = async () => {
    try {
        const response = await axios.post(`${AUTH_BASE_URL}/logout`, {}, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        });
        document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    } catch (error) {
        console.log("LOGOUT ERROR : ", error);
    }
}

// id 찾기
export const findId = async (phone) => {
    try {
        const response = await axios.get(`${AUTH_BASE_URL}/id`, {
            params: {
                phone: phone
            },
        })
        if (response.status === 200) {
            return `회원님의 ID는 ${response.data.user_id} 입니다.`;
        }
    } catch (error) {
        if (error.response?.status === 420) {
            alert('등록된 휴대폰 번호가 없습니다.');
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.log("ID FIND ERROR : ", error);
    }
}

// 비밀번호 찾기
export const findPw = async (id, phone) => {
    try {
        const response = await axios.post(`${AUTH_BASE_URL}/password`, {
            phone: phone,
            user_id: id
        })
        if (response.status === 200) {
            return "임시비밀번호가 해당 번호로 발송되었습니다.";
        }
    } catch (error) {
        if (error.response?.status === 420) {
            alert('등록된 휴대폰 번호가 없습니다.');
        } else if (error.response?.status === 421) {
            alert("등록된 아이디가 없습니다.");
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.log("PW FIND ERROR : ", error);
    }
}

// 지점에 해당하는 FC 리스트 받아오기
export const getFinancialList = async (dealer_affiliation) => {
    try {
        const response = await axios.get(`${UTIL_BASE_URL}/fc_list`, {
            params: {
                "dealer_affiliation": dealer_affiliation
            },
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        });
        return response;
    } catch (error) {
        if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        console.error("FC LIST FETCH ERROR : ", error);
    }
}

// 시공 요청서 작성
export const createRequest = async ({request}) => {
    try {
        const response = await axios.post(`${UTIL_BASE_URL}/job_request`, request,
            {headers: {Bearer: `${getCookie('session_id')}`}});
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 413) {
            alert("이미 존재하는 차대번호입니다.");
        } else if (error.response?.status === 430) {
            alert("요청서 작성이 불가능합니다. 담당자분께 확인 부탁드립니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("REQUEST CREATION ERROR : ", error);
    }
};

// 시공 요청서 목록 받아오기 (Admin 제외)
export const getRequestList = async (searchCondition, currentPage) => {
    try {
        const response = await axios.get(`${UTIL_BASE_URL}/job_request_list`, {
            params: {
                customer_name: searchCondition.customer_name,
                process_status: searchCondition.process_status,
                vehicle_info_number: searchCondition.vehicle_info_number,
                car_type: searchCondition.car_type,
                registration_date: searchCondition.registration_date,
                dealer_affiliation: searchCondition.dealer_affiliation,
                agency_affiliation: searchCondition.agency_affiliation,
                sales_person_name: searchCondition.sales_person_name,
                page: currentPage,
            },
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
        });
        return response;
    } catch (error) {
        if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        console.error("Error fetching request list : ", error);
    }
};

// 전체 시공 요청서 목록 받아오기 (Admin 전용)
export const getAllRequest = async (searchCondition, currentPage) => {
    try {
        const response = await axios.get(`${ADMIN_BASE_URL}/job_request_list`, {
            params: {
                dealer_affiliation: searchCondition.dealer_affiliation,
                dealer_location: searchCondition.dealer_location,
                agency_affiliation: searchCondition.agency_affiliation,
                sales_person_name: searchCondition.sales_person_name,
                customer_name: searchCondition.customer_name,
                vehicle_info_number: searchCondition.vehicle_info_number,
                process_status: searchCondition.process_status,
                registration_date: searchCondition.registration_date,
                page: currentPage,
            },
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
        });
        return response;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error fetching all request for admin : ", error);
    }
}

// 시공 요청서 상태 변경 (FC 전용)
export const modifyRequestStatus = async (patchData) => {
    try {
        const response = await axios.put(`${UTIL_BASE_URL}/job_request`, patchData, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        });
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 422) {
            alert("변경하려는 상태가 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error modifying request status : ", error);
    }
}

// 시공요청서 엑셀 다운로드
export const getRequestExcel = async (role, searchCondition) => {
    let URL;
    if (role === 'admin') {
        URL = `${ADMIN_BASE_URL}`
    } else if (role === 'agency' || role === "financial" || role === "dealer") {
        URL = `${UTIL_BASE_URL}`
    } else {
        return;
    }
    try {
        const response = await axios.get(`${URL}/job_request_list_excel`, {
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
            params: {
                dealer_affiliation: searchCondition.dealer_affiliation,
                dealer_location: searchCondition.dealer_location,
                sales_person_name: searchCondition.sales_person_name,
                customer_name: searchCondition.customer_name,
                vehicle_info_number: searchCondition.vehicle_info_number,
                contract_number: searchCondition.contract_number,
                process_status: searchCondition.process_status,
                registration_date: searchCondition.registration_date,
                car_type: searchCondition.car_type,
            },
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'smith_request_list.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 411) {
            alert("엑셀 생성에 실패했습니다. 잠시 후 다시 시도해주세요.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error fetching request excel : ", error);
    }
}

// 배정할 대리점 검색
export const searchAgency = async (searchCondition) => {
    try {
        const response = await axios.get(`${ADMIN_BASE_URL}/agency_list`, {
            params: {
                "agency_search": searchCondition,
            },
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
        });
        return response;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error fetching agency list : ", error);
    }
}

// 대리점 배정
export const allocateAgency = async (patchData) => {
    try {
        const response = await axios.put(`${ADMIN_BASE_URL}/job_request`, patchData, {
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
        })
        return response.status;
    } catch (error) {
        console.error("Error allocating agency to request : ", error);
        return error.response?.status;
    }
}

// 시공 요청서 수정
export const modifyRequestDetail = async (role, patchData) => {
    let URL = role === 'admin' ? `${ADMIN_BASE_URL}` : role === 'dealer' ? `${UTIL_BASE_URL}` : "";
    try {
        const response = await axios.patch(`${URL}/job_request`, patchData, {
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 413) {
            alert("이미 존재하는 차대번호입니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error modifying request detail : ", error);
    }
}

// 시공완료 & 시공 보증서 생성 (Agency)
export const createWarranty = async (postData) => {
    try {
        const response = await axios.post(`${UTIL_BASE_URL}/job_guarantee`, postData, {
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 423) {
            alert("틴팅 농도가 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else if (error.response?.status === 425) {
            alert("재고량이 부족하여 처리할 수 없습니다.");
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error creating warranty : ", error);
    }
}

// 특정 보증서 정보 받아오기
export const getWarrantyData = async (id) => {
    try {
        const response = await axios.get(`${UTIL_BASE_URL}/job_guarantee`, {
            params: {
                job_request_id: id,
            },
            headers: {
                Bearer: `${getCookie('session_id')}`,
            }
        })
        return response;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error fetching warranty data : ", error);
    }
}

// 시공완료 상태의 보증서 수정
export const modifyWarranty = async (patchData) => {
    try {
        const response = await axios.patch(`${UTIL_BASE_URL}/job_guarantee`, patchData, {
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error modifying warranty : ", error);
    }
}

// 보증서 목록 받아오기
export const getWarrantyList = async (searchCondition, currentPage) => {
    try {
        const response = await axios.get(`${ADMIN_BASE_URL}/guarantee_list`, {
            params: {
                dealer_affiliation: searchCondition.dealer_affiliation,
                dealer_location: searchCondition.dealer_location,
                sales_person_name: searchCondition.sales_person_name,
                customer_name: searchCondition.customer_name,
                agency_affiliation: searchCondition.agency_affiliation,
                car_number: searchCondition.car_number,
                warranty_number: searchCondition.warranty_number,
                page: currentPage,
            },
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
        })
        return response;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error fetching guarantee list : ", error);
    }
}

// 보증서 html 보기 (admin)
export const viewWarrantyDetail = async (id) => {
    try {
        const response = await axios.get(`${ADMIN_BASE_URL}/guarantee_print`, {
            params: {
                id: id,
            },
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error viewing warranty in html form : ", error);
    }
}

//보증서 엑셀 다운로드
export const getWarrantyExcel = async (searchCondition) => {
    try {
        const response = await axios.get(`${ADMIN_BASE_URL}/guarantee_list_excel`, {
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
            params: {
                dealer_affiliation: searchCondition.dealer_affiliation,
                dealer_location: searchCondition.dealer_location,
                sales_person_name: searchCondition.sales_person_name,
                customer_name: searchCondition.customer_name,
                agency_affiliation: searchCondition.agency_affiliation,
                car_number: searchCondition.car_number,
                warranty_number: searchCondition.warranty_number,
            },
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'smith_warranty_list.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 411) {
            alert("엑셀 생성에 실패했습니다. 잠시 후 다시 시도해주세요.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error fetching warranty excel : ", error);
    }
}

// 재고 내역 받아오기
export const GetInventoryLog = async (searchCondition, currentPage) => {
    try {
        const response = await axios.get(`${ADMIN_BASE_URL}/inventory_logs`, {
            params: {
                user_name: searchCondition.user_name,
                registration_date: searchCondition.registration_date,
                status: searchCondition.status,
                film_type: searchCondition.film_type,
                agency_affiliation: searchCondition.agency_affiliation,
                page: currentPage,
            },
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        });
        return response;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error fetching inventory log : ", error);
    }
}

// 전체 사용자 목록 받아오기
export const getUserList = async (searchCondition, currentPage) => {
    try {
        const response = await axios.get(`${ADMIN_BASE_URL}/user_list`, {
            params: {
                authority_type: searchCondition.authority_type,
                user_name: searchCondition.user_name,
                id: searchCondition.id,
                is_active: searchCondition.is_active,
                affiliation: searchCondition.affiliation,
                page: currentPage,
            },
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
        });
        return response;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("ERROR FETCHING USER LIST : ", error);
    }

}

// 사용자 등록 (admin)
export const registerUser = async (userData) => {
    try {
        const response = await axios.post(`${ADMIN_BASE_URL}/user`, userData, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error registering user : ", error);
    }
}

// 비밀번호 초기화 (admin)
export const resetPassword = async (data) => {
    try {
        const response = await axios.put(`${ADMIN_BASE_URL}/user_password`, data, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        });
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error resetting user password : ", error);
    }
}

// 재고 추가 (admin)
export const addStock = async (patchData) => {
    try {
        const response = await axios.put(`${ADMIN_BASE_URL}/user_stock`, patchData, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else if (error.response?.status === 425) {
            alert("재고량을 확인해주세요.");
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error adding stock : ", error);
    }
}

// 사용자 수정
export const modifyUser = async (patchData) => {
    try {
        const response = await axios.patch(`${ADMIN_BASE_URL}/user`, patchData, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 412) {
            alert("본사 계정은 소속을 지정할 수 없습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error modifying user data : ", error);
    }
}

// 코드 리스트 받아오기
export const getCodeList = async (code) => {
    try {
        const response = await axios.get(`${UTIL_BASE_URL}/getCodeList`, {
            params: {
                code: code,
            },
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.data.code_list;
    } catch (error) {
        alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        console.error("Error fetching code list : ", error);
    }
}

// 코드 등록
export const addCode = async (data) => {
    try {
        const response = await axios.post(`${ADMIN_BASE_URL}/code`, data, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else if (error.response?.status === 430) {
            alert("이미 존재하는 코드입니다.");
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("CODE ADDITION ERROR : ", error);
    }
}

// 코드 삭제
export const deleteCode = async (code) => {
    code = code.join(",");
    try {
        const response = await axios.delete(`${ADMIN_BASE_URL}/code`, {
            params: {
                code: code
            },
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("CODE DELETION ERROR : ", error);
    }
}

// 코드 수정
export const modifyCode = async (patchData) => {
    try {
        const response = await axios.patch(`${ADMIN_BASE_URL}/code`, patchData, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("CODE MODIFY ERROR : ", error);
    }
}

// 특정 사용자 정보 받아오기
export const getUserInfo = async () => {
    try {
        const response = await axios.get(`${AUTH_BASE_URL}/my_info`, {
            headers: {
                Bearer: `${getCookie('session_id')}`,
            }
        });
        return response;
    } catch (error) {
        if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        console.error("Error getting my info : ", error);
    }
};

// 비밀번호 변경
export const modifyPassword = async (pwInfo) => {
    try {
        const response = await axios.patch(`${AUTH_BASE_URL}/password`, pwInfo, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        });
        return response.status;
    } catch (error) {
        if (error.response?.status === 419) {
            alert("기존 비밀번호가 일치하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error modifying password : ", error);
    }
}

// 내 정보 수정
export const modifyUserInfo = async (userInfo) => {
    try {
        const response = await axios.patch(`${AUTH_BASE_URL}/my_info`, userInfo, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        });
        return response.status;
    } catch (error) {
        if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        console.error("Error modifying my info : ", error);
    }
};

// 사용자 엑셀 다운로드
export const getUserExcel = async (searchCondition) => {
    try {
        const response = await axios.get(`${ADMIN_BASE_URL}/user_list_excel`, {
            headers: {
                Bearer: `${getCookie('session_id')}`,
            },
            params: {
                authority_type: searchCondition.authority_type,
                user_name: searchCondition.user_name,
                id: searchCondition.id,
                is_active: searchCondition.is_active,
                dealer_affiliation: searchCondition.dealer_affiliation,
                agency_affiliation: searchCondition.agency_affiliation,
            },
            responseType: 'blob',
        });
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'smith_user_list.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 411) {
            alert("엑셀 생성에 실패했습니다. 잠시 후 다시 시도해주세요.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
        console.error("Error fetching user excel : ", error);
    }
}

// 주소 검색
export const searchAddress = async (keyword, countPerPage = 10, currentPage = 1) => {
    try {
        const response = await axios.get(`${SRC_BASE_URL}/address`, {
            params: {
                keyword: keyword,
                countPerPage: countPerPage,
                currentPage: currentPage,
            }
        });
        return response;
    } catch (error) {
        alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        console.error("Error fetching address searching : ", error);
    }
}

// 전체 대리점 코드 조회
export const getAgencyCodeList = async () => {
    try {
        return await axios.get(`${ADMIN_BASE_URL}/agency_code_list`, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        });
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
    }
}

// 딜러 - 대리점 연결 조회
export const getConnection = async (authType, dealerCode) => {
    try {
        let url = authType === 'admin' ? ADMIN_BASE_URL : UTIL_BASE_URL;
        return await axios.get(`${url}/dasa_relation`, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            },
            params: {
                dealer_affiliation_code: dealerCode,
            }
        });
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
    }
}

// 딜러 - 대리점 연결 추가 및 수정
export const addOrUpdateConnection = async (dealerCode, agencyCode) => {
    try {
        const response = await axios.post(`${ADMIN_BASE_URL}/dasa_relation`, {
            dealer_affiliation_code: dealerCode,
            agency_affiliation_code: agencyCode,
        }, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.status;
    } catch (error) {
        console.log(error.response);
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
    }
}

// 공지사항 저장
export const saveNotice = async (type, noti, activeOption) => {
    console.log(noti);
    try {
        const response = await axios.post(`${ADMIN_BASE_URL}/notice`, {
            target: type,
            content: noti,
            activation: activeOption,
        }, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.status;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
    }
}

// 공지사항 불러오기 (ADMIN)
export const getNoticeADMIN = async () => {
    try {
        const response = await axios.get(`${ADMIN_BASE_URL}/notice`, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.data.notice_dump;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
    }
}

// 공지사항 불러오기 (UTIL)
export const getNoticeUTIL = async () => {
    try {
        const response = await axios.get(`${UTIL_BASE_URL}/notice`, {
            headers: {
                Bearer: `${getCookie('session_id')}`
            }
        })
        return response.data;
    } catch (error) {
        if (error.response?.status === 410) {
            alert("권한이 유효하지 않습니다.");
        } else if (error.response?.status === 401) {
            alert("세션이 만료되었습니다. 다시 로그인해주세요.");
            document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
            return error.response.status;
        } else {
            alert("오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
    }
}