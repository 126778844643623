import "../styles/SideDrawer.css";
import {useEffect, useState} from "react";
import * as React from "react";
import {useRecoilState} from "recoil";
import {IsLogin, userInfo} from "./UserInfo";
import {LogOut} from "../api/Api";
import {NavLink} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

export default function SideDrawer({show, onClose}) {
    const [isLogin, setIsLogin] = useRecoilState(IsLogin);
    const [userData, setUserData] = useRecoilState(userInfo);
    const [showSide, setShowSide] = useState(false);
    const isNotMobile = useMediaQuery({query: "(min-width:601px"});
    const handleBackgroundClick = (e) => {
        if (e.target.classList.contains("modal-wrapper")) {
            e.stopPropagation();
            setShowSide(false);
            setTimeout(() => onClose(), 200);
        }
    };

    useEffect(() => {
        if (isNotMobile) {
            onClose();
        }
    }, [isNotMobile]);

    const handleLogout = async () => {
        const log_out = await LogOut();
        setIsLogin(false);
        setUserData({});
    }

    useEffect(() => {
        setShowSide(show);
    }, [show]);

    const clickHandler = () => {
        setShowSide(false);
        setTimeout(() => onClose(), 200);
    }
    return (
        <div className="modal-wrapper" onClick={handleBackgroundClick}>
            <div className={`side-drawer ${showSide ? 'open' : ''}`}>
                <span className="close" onClick={() => {
                    setShowSide(false);
                    setTimeout(() => onClose(), 200);
                }}>&times;</span>
                <div>
                    <ul>
                        {userData.authority_type !== 'admin' &&
                            <li onClick={clickHandler}>
                                <NavLink to="/myInfo/info" activeClassName="active">내 정보</NavLink>
                            </li>
                        }
                        <li onClick={clickHandler}>
                            <NavLink to="/req/list" activeClassName="active">시공 요청서</NavLink>
                        </li>
                        {userData.authority_type === 'admin' &&
                            <li onClick={clickHandler}>
                                <NavLink to="/grant/list" activeClassName="active">시공 보증서</NavLink>
                            </li>
                        }
                        {userData.authority_type === 'admin' &&
                            <li onClick={clickHandler}>
                                <NavLink to="/req/stock/list" activeClassName="active">재고 내역</NavLink>
                            </li>
                        }
                        {userData.authority_type === 'admin' &&
                            <li onClick={clickHandler}>
                                <NavLink to="/admin/user/list" activeClassName="active">사용자 관리</NavLink>
                            </li>
                        }
                        {userData.authority_type === 'admin' &&
                            <li onClick={clickHandler}>
                                <NavLink to="/code/info" activeClassName="active">코드 관리</NavLink>
                            </li>
                        }
                        {userData.authority_type === 'admin' &&
                            <li onClick={clickHandler}>
                                <NavLink to="/popup/list" activeClassName="active">팝업 관리</NavLink>
                            </li>
                        }
                        {userData.authority_type === 'admin' &&
                            <li onClick={clickHandler}>
                                <NavLink to="/req/noti/list" activeClassName="active">공지사항</NavLink>
                            </li>
                        }
                    </ul>
                    {
                        userData.authority_type === 'agency' &&
                        <div className="inventoryStatus">
                            <div>재고현황</div>
                            <div>
                                <div>Authentic-L</div>
                                <div>{Math.round(userData.stock * 100) / 100} (m)</div>
                            </div>
                        </div>
                    }
                    <div className="accessController">
                        <p>{userData.name}님</p>
                        <button id="logoutBtn" className="grayBtn" onClick={handleLogout}>로그아웃</button>
                    </div>
                </div>
            </div>
        </div>
    )
}